<template>
    <v-card min-height="450px">
        <v-card-title>Security</v-card-title>
        <v-card-subtitle>Security Configurations</v-card-subtitle>

        <v-card-text>
            <ValidationObserver ref="observer" v-slot="{}">
                <v-form>
                    <v-card-text>
                        <v-row dense>
                            <v-col cols="12">
                                <CustomLabel
                                    for="current_password"
                                    label="Current Password"
                                    required
                                />
                                <CustomTextField
                                    id="current_password"
                                    inputIcon="mdi-lock"
                                    inputType="password"
                                    inputPlaceholder="Current Password"
                                    :inputValue="
                                        updateCredentialPayload.current_password
                                    "
                                    validationName="current_password"
                                    validationRules="required"
                                    @input="
                                        updateForm(
                                            $event,
                                            'updateCredentialPayload.current_password'
                                        )
                                    "
                                />
                            </v-col>
                        </v-row>

                        <v-row dense>
                            <v-col cols="12" sm="6">
                                <CustomLabel
                                    for="new_password"
                                    label="New Password"
                                    required
                                />

                                <CustomTextField
                                    id="new_password"
                                    inputIcon="mdi-lock"
                                    inputType="password"
                                    inputPlaceholder="New Password"
                                    :inputValue="
                                        updateCredentialPayload.new_password
                                    "
                                    validationName="new_password"
                                    validationRules="required|password:@confirm_password"
                                    @input="
                                        updateForm(
                                            $event,
                                            'updateCredentialPayload.new_password'
                                        )
                                    "
                                />
                            </v-col>

                            <v-col cols="12" sm="6">
                                <CustomLabel
                                    for="confirm_password"
                                    label="Confirm Password"
                                    required
                                />
                                <CustomTextField
                                    id="confirm_password"
                                    inputIcon="mdi-lock"
                                    inputType="password"
                                    inputPlaceholder="Confirm Password"
                                    :inputValue="
                                        updateCredentialPayload.confirm_password
                                    "
                                    validationName="confirm_password"
                                    validationRules="required|password:@new_password"
                                    @input="
                                        updateForm(
                                            $event,
                                            'updateCredentialPayload.confirm_password'
                                        )
                                    "
                                />
                            </v-col>
                        </v-row>
                    </v-card-text>

                    <v-card-actions class="px-3">
                        <v-spacer
                            class="d-flex justify-space-between align-end"
                        />
                        <CustomButton
                            :block="$vuetify.breakpoint.xs"
                            btnColour="primary"
                            :btnIsLoading="updateCredentialOps.isLoading"
                            btnLabel="Save"
                            @click="updateCredentials(updateCredentialPayload)"
                        />
                    </v-card-actions>
                </v-form>
            </ValidationObserver>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapState } from "vuex";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";

extend("required", {
    ...required,
    message: "Field cannot be empty.",
});

extend("password", {
    params: ["target"],
    validate(value, { target }) {
        return value === target;
    },
    message: "Password doesn't match.",
});

export default {
    computed: {
        ...mapState("userSelfMgmt", [
            "updateCredentialOps",
            "updateCredentialPayload",
        ]),
    },
    data: () => ({}),
    methods: {
        async updateCredentials(payload) {
            let valid = await this.$refs.observer.validate();

            if (valid) {
                let response = await this.$store.dispatch(
                    "userSelfMgmt/updateSelfCredential",
                    payload
                );

                if (response) {
                    this.$refs.observer.reset();
                }
            }
        },
        updateForm(event, form) {
            if (event instanceof InputEvent) {
                this.$store.commit("userSelfMgmt/UPDATE_FORM", {
                    payload: {
                        form: form,
                        value: event.target.value,
                    },
                });
                return;
            } else if (
                event instanceof String ||
                typeof event == "boolean" ||
                typeof event == "number" ||
                typeof event == "string"
            ) {
                this.$store.commit("userSelfMgmt/UPDATE_FORM", {
                    payload: {
                        form: form,
                        value: event,
                    },
                });
                return;
            } else {
                return;
            }
        },
    },
    name: "TabUserSecurity",
    // Lifecycle hooks.
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
};
</script>