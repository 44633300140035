var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"min-height":"450px"}},[_c('v-card-title',[_vm._v("Security")]),_c('v-card-subtitle',[_vm._v("Security Configurations")]),_c('v-card-text',[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){return [_c('v-form',[_c('v-card-text',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('CustomLabel',{attrs:{"for":"current_password","label":"Current Password","required":""}}),_c('CustomTextField',{attrs:{"id":"current_password","inputIcon":"mdi-lock","inputType":"password","inputPlaceholder":"Current Password","inputValue":_vm.updateCredentialPayload.current_password,"validationName":"current_password","validationRules":"required"},on:{"input":function($event){return _vm.updateForm(
                                        $event,
                                        'updateCredentialPayload.current_password'
                                    )}}})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('CustomLabel',{attrs:{"for":"new_password","label":"New Password","required":""}}),_c('CustomTextField',{attrs:{"id":"new_password","inputIcon":"mdi-lock","inputType":"password","inputPlaceholder":"New Password","inputValue":_vm.updateCredentialPayload.new_password,"validationName":"new_password","validationRules":"required|password:@confirm_password"},on:{"input":function($event){return _vm.updateForm(
                                        $event,
                                        'updateCredentialPayload.new_password'
                                    )}}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('CustomLabel',{attrs:{"for":"confirm_password","label":"Confirm Password","required":""}}),_c('CustomTextField',{attrs:{"id":"confirm_password","inputIcon":"mdi-lock","inputType":"password","inputPlaceholder":"Confirm Password","inputValue":_vm.updateCredentialPayload.confirm_password,"validationName":"confirm_password","validationRules":"required|password:@new_password"},on:{"input":function($event){return _vm.updateForm(
                                        $event,
                                        'updateCredentialPayload.confirm_password'
                                    )}}})],1)],1)],1),_c('v-card-actions',{staticClass:"px-3"},[_c('v-spacer',{staticClass:"d-flex justify-space-between align-end"}),_c('CustomButton',{attrs:{"block":_vm.$vuetify.breakpoint.xs,"btnColour":"primary","btnIsLoading":_vm.updateCredentialOps.isLoading,"btnLabel":"Save"},on:{"click":function($event){return _vm.updateCredentials(_vm.updateCredentialPayload)}}})],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }